.layout-page-wrapper {
    height: 100vh;

    .logo {
        height: 35px;
        margin: 15px 0px;
    }
    .logo-collapsed {
        height: 40px;
        margin: 10px 0px;
    }
    .site-layout {
        .site-layout-header-background {
            background: #fff;
            display: flex;
            .icon-wrapper :hover {
                background-color: #fafafa;
            }
            h1 {
                width: 100%;
            }
        }
        .site-layout-background {
            background: #fff;
        }
    }
}
